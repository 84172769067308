// for hu
import raMessage from 'ra-language-hungarian';
import en from './en';

const { ra } = raMessage;
const message: typeof en = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: '',
        toggle_hidden: '',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: 'Nem található',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'OK',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: '%{name} törlése?',
    },
  },
  admin: {
    title: {
      normal: 'Smart Construction Pilot',
      split1: 'Smart Construction',
      split2: 'Pilot',
    },
    copyright: 'Copyright © EARTHBRAIN Ltd. All rights reserved.',
    approval: 'Jóváhagyásra vár',
    usermenu: {
      userNotice: 'Értesítés',
      terms: 'Használati feltételek',
      manual: 'Kézi',
      downloads: 'Letöltés',
    },
    breadcrumbs: {
      details: 'Részletek',
      retrofitEdit: 'Részletek',
      taskCreate: 'Munka létrehozása',
      taskEdit: 'Munka részletei',
      geofenceCreate: 'Létrehozás',
      geofenceEdit: 'Szerkesztés',
      geofenceAlertList: 'Geokerítés riasztás',
      geofenceAlertShow: 'Részletek',
      compactionWorkAreaCreate: 'Tömörítési munkaterület létrehozása',
      compactionWorkAreaShow: 'Tömörítési munkaterületek',
      compactionWorkAreaEdit: 'Tömörítési munkaterület szerkesztése',
      compactionLayerCreate: 'Tömörítési rétegek létrehozása',
      compactionLayerEdit: 'Tömörítési rétegek szerkesztése',
      supportMode: 'Támogatási üzemmód',
      retrofitManage: 'Vezérlőregisztráció',
      retrofitManageAdmin: 'Vezérlőregisztráció (rendszergazda)',
      retrofitNamePlateFiles: 'Vezérlőregisztráció',
      retrofitAlternateRegistCreate: 'Vezérlő megbízotti regisztrációja',
      retrofitAlternateRegistEdit: 'Részletek',
      retrofitManageAdminBulkActionCreate: 'Regisztráció',
      terms: 'Használati feltételek',
      downloads: 'Letöltés',
    },
    pages: {
      siteList: 'Munkaterület-lista',
      siteRetrofitCreate: 'Gép munkaterülethez adása',
      siteRetrofitEdit: '',
      siteBucketCreate: 'Kanál munkaterülethez adása',
      siteBucketEdit: 'Kanál részletei',
      siteProjectEdit: 'Projekt részletei',
      siteProjectCreate: 'Projektfájl regisztrálása',
      siteExtensionarmCreate: 'Kanálszár toldat munkaterülethez adása',
      taskCreate: 'Munka létrehozása',
      taskEdit: 'Munka részletei',
      geofenceAlertList: 'Geokerítés riasztás',
      geofenceAlertShow: 'Geokerítés riasztás részletei',
      geofenceCreate: 'Geokerítés létrehozása',
      geofenceEdit: 'Geokerítés szerkesztése',
      compactionWorkAreaCreate: 'Tömörítési munkaterület létrehozása',
      compactionLayerCreate: 'Tömörítési rétegek létrehozása',
      compactionLayerEdit: 'Tömörítési rétegek szerkesztése',
      siteFileEdit: 'Részletek',
      siteFileCreate: 'Fájl munkaterülethez adása',
      retrofitList: 'Géplista',
      retrofitEdit: '',
      retrofitRentalCreate: 'Hozzárendelés regisztrálása',
      retrofitTransferCreate: 'Áthelyezés regisztrálása',
      retrofitSendBack: 'Visszaküldés regisztrálása',
      bucketList: 'Kanállista',
      bucketEdit: 'Kanál részletei',
      bucketCreate: 'Kanál regisztrálása',
      bucketRentalCreate: 'Kanál megosztása',
      extensionarmList: 'Kanálszár toldatok listája',
      extensionarmEdit: 'Kanálszár toldat részletei',
      extensionarmShare: 'Kanálszár toldat megosztása',
      retrofitBulkActionList: 'Kötegelt regisztrációs lista',
      retrofitBulkActionCreate: 'Kötegelt regisztráció',
      retrofitBasicInfoList: 'Vezérlőlista',
      retrofitBasicInfoCreate: 'Vezérlőregisztráció',
      retrofitBasicInfoEdit: 'Vezérlő frissítése',
      gnssSettingList: 'Ntrip-beállítások listája',
      gnssSettingCreate: 'Ntrip-beállítások létrehozása',
      gnssSettingAdminCreate: 'Ntrip-beállítások létrehozása',
      gnssSettingOwnerCreate: 'Ntrip-beállítások létrehozása',
      gnssSettingEdit: 'Ntrip-beállítások szerkesztése',
      gnssSettingAdminEdit: 'Ntrip-beállítások szerkesztése',
      gnssSettingOwnerEdit: 'Ntrip-beállítások szerkesztése',
      approvalList: 'Géplista',
      retrofitRentalEdit: 'Hozzárendelési részletek',
      retrofitTransferEdit: 'Áthelyezési részletek',
      asbuiltsCreate: 'Építési eredmény lekérése',
      asbuiltsShow: 'Megépítési előzmények',
      asbuiltsRetrofitCreate: 'Építési eredmény lekérése',
      retrofitConfigList: 'Konfigurációs információ',
      retrofitKitInfoList: 'Vezérlőadatok',
      retrofitAccuraciesList: 'Pontosságellenőrzési eredmény',
      retrofitAccuraciesShow: 'Részletek',
      retrofitCalibaList: 'Kalibrációs adatok',
      retrofitCalibInfo: 'Gépkalibrálási részletek',
      retrofitBasicSettingList: 'Alapbeállítások információi',
      retrofitErrorList: 'Hibainformációk',
      retrofitErrorShow: 'Részletek',
      retrofitTopographicSurveyList: 'Topográfiai felmérési lista',
      retrofitTopographicSurveyShow: 'Topográfiai felmérés részletei',
      retrofitShareFileList: 'Alkalmazási napló',
      retrofitCorporationLicenseList: 'Licencek listája',
      retrofitCorporationLicenseEdit: 'Licencek részletei',
      CompactionLayerList: 'Tömörítési rétegek',
      userNoticeList: 'Értesítések listája',
      userNoticeCreate: 'Értesítés hozzáadása',
      userNoticeEdit: 'Értesítés frissítése',
      userNoticeDrawer: 'Értesítés',
      retrofitAlternateRegists: 'Vezérlő megbízotti regisztrációs lista',
      firmwareList: 'Firmware-lista',
      firmwareShow: 'Firmware részletei',
      firmwareCreate: 'Firmware hozzáadása',
      retrofitManage: 'Szöveg',
      retrofitNamePlateFiles: 'Fotó',
      retrofitManageAdminBulkAction: 'Kötegelt regisztráció',
      retrofitManageAdminBulkActionCreate: 'Kötegelt regisztráció',
      retrofitAlternateRegistCreate: 'Vezérlő megbízotti regisztrációja',
      retrofitAlternateRegistEdit: 'Vezérlő megbízotti regisztráció részletei',
      retrofitAlternateRegistCreateText: 'Szöveg',
      retrofitAlternateRegistCreatePhoto: 'Fotó',
      ntripList: 'Ntrip információ',
      ntripEdit: 'Ntrip felügyelete',
    },
    label: {
      login: 'Bejelentkezés',
      selected: 'Kiválasztva (%{length} eset)',
      listNoResults: 'Adat nem található',
      maintenance: {
        title: 'Jelenleg karbantartás folyik.',
        message: `Karbantartás miatt átmenetileg felfüggesztettük a szolgáltatásokat.
      Elnézést kérünk az okozott kellemetlenségért, de kérjük, várjon még egy pillanatot.`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, ln3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder:
          'Húzza ide vagy válassza ki a feltöltendő fájl(oka)t (%{fileTypes}).',
        maxFileNum: 'Az egyszerre regisztrálható vezérlők maximális száma öt.',
      },
      nosetting: 'Nincs beállítás',
      siteRetrofitList: {
        remoteSupport: 'Távoli támogatás',
      },
      retrofitEdit: {
        breadcrumbs: 'Részletek',
        belongsDetails: '',
        ntripSettingInfo: 'Ntrip-beállítások információi',
        machineryDetails: 'Gép',
        retrofitDetails: 'Vezérlő',
        basicInfo: 'Alapadatok',
        controller: 'Vezérlő',
        reciverMain: 'Fő GNSS-vevő',
        reciverSub: 'Mellék GNSS-vevő',
        retrofitstatus: 'Vezérlőállapot',
        license: 'Licenc',
        licenseId: 'Licencazonosító',
        expiredDate: 'Lejárati dátum',
      },
      retrofitRental: {
        rentalDetails: 'Hozzárendelési részletek',
        rentalInfo: 'Hozzárendelési részletek',
        lending: 'Hozzárendelés',
        return: 'Vissza',
        lendingDestination: 'Hozzárendelt vállalat',
        lendingCompany: 'Cég neve',
        lendingEmail: 'E-mail',
        companyName: 'Cég neve',
      },
      retorfitTransfer: {
        tab: 'Áthelyezés',
        transferInfo: 'Áthelyezési részletek',
        transferButton: 'Áthelyezés',
        transferEditButton: 'Áthelyezés szerkesztése',
      },
      siteConfigureEdit: {
        onButton: 'BE',
        offButton: 'KI',
      },
      approvals: {
        rentalTab: 'Hozzárendelés',
        transferTab: 'Áthelyezés',
      },
      buckets: {
        bucketTab: 'Kanál',
        extensionarmTab: 'Kanálszár toldat',
        extensionarmFile: 'Kanálszár toldat fájl',
        shareTo: 'Megosztás ide',
      },
      bucketRental: {
        corporation: 'Megosztás ide',
        bucketType: 'Típus',
        bk3File: 'BK3-fájl',
      },
      retrofitBasic: {
        basicInfoTab: 'Alapadatok',
        bulkActionTab: 'Kötegelt regisztráció',
        ntripTab: 'Ntrip információ',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'Visszaküldés',
        initialize: 'Inicializálás',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: 'Kötegelt frissítés',
        retrofitDistributor: 'Forgalmazó',
        retrofitRegion: 'Régió',
        corporationId: 'Vállalati azonosító',
        corporationName: 'Cég neve',
      },
      retrofitBasicInfoEdit: {
        corporationId: 'Használt vállalati azonosító',
        corporationName: 'Használt vállalat',
      },
      gnssSettingsEdit: {
        ownerCorporationName: 'Cég neve',
      },
      terms: {
        title: 'Használati feltételek',
        message:
          'Kattintson a lenti hivatkozásra a Használati feltételek elolvasásához.',
        confirmMessage1:
          'A Smart Construction Pilot használatához el kell fogadnia az alábbi dokumentumo(ka)t.',
        confirmMessage2:
          'Ha nem fogadja el, nem fogja tudni használni a Smart Construction Pilot.',
        select: 'Válassza ki a régióját.',
      },
      downloads: {
        title: 'Projektfájl-átalakító',
        message:
          'Ez egy különálló alkalmazás, amely projektfájlt készít a tervfájlokból.',
        button: 'Letöltés',
      },
      retrofitBasicInfoCreate: {
        uuid: 'Automatikus számozás',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'Letöltés',
        resultFileDownload: 'Letöltés',
      },
      tasks: {
        coordinate: 'Koordináták (egység:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'Blokkméret (%{unit})',
      },
      compactionLayers: {
        thickness: 'Vastagság (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: 'Részletek',
        rentalDetail: 'Hozzárendelési részletek',
      },
      pendingTransfers: {
        breadcrumbs: 'Részletek',
        transferDetail: 'Áthelyezési részletek',
      },
      pendingAlternateRegists: {
        breadcrumbs: 'Részletek',
        alternateRegistDetail: 'Vezérlő megbízotti regisztráció részletei',
      },
      supportMode: {
        retrofit: 'Gép',
        optional: 'Választható',
      },
      retrofitList: {
        assignment: 'Hozzárendelés',
        license: 'Licenc',
      },
      userNotices: {
        languageDetails: 'Értesítés tartalma',
        language: 'Nyelv',
        languageEn: 'Angol',
        languageJa: 'Japán',
        languageFr: 'Francia',
        languageDe: 'Német',
        languageEs: 'Spanyol',
        Information: 'Értesítés',
        Maintenance: 'Karbantartás',
        emergency: 'Vészhelyzet',
        important: 'Fontos',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'Vezérlőregisztráció',
        corporationName: 'Cég neve',
        basicInfoSerialNumber: 'Sorozatszám',
        confirmInput: 'Sorozatszám újból',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'Sorozatszám',
        status: 'Állapot',
        imageFile: 'Kép',
        message1:
          'Készítsen éles, nem elmosódott képet a vezérlő sorozatszámáról vagy a QR-kódról.',
        message2: 'A képek legyenek %{mbyte} MB-nál kisebb méretűek.',
        errormessage:
          'Az egyszerre regisztrálható vezérlők maximális száma öt.',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: 'Érvényes',
          disable: 'Érvénytelen',
          unLicense: 'Nincs kiosztva',
        },
        machine: 'Gép',
        before: 'Ez előtt:',
        after: 'Ez után:',
      },
      geofences: {
        project: 'Projekt',
        projectSourceLayer: 'Réteg',
        rgb: 'Szín (RGB)',
        select: 'Kiválasztás geokerítése',
        coordinates: 'Koordináták',
        coordinatesLabel: 'Koordináták (N,E)',
        unitLength: 'Egység:%{unit}',
      },
      geofenceDialog: {
        coordinate: 'Koordináták (egység:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: 'Sugár',
      },
      retrofitAlternateRegists: {
        menuTitle: 'Vezérlő megbízotti regisztrációja',
        corporationName: 'Cég neve',
        email: 'E-mail',
        basicInfoSerialNumber: 'Sorozatszám',
        confirmInput: 'Sorozatszám újból',
      },
      ntripList: {
        unusedTotal: 'Használatlan összesen',
      },
      calibrationDetail: {
        breadcrumbs: 'Részletek',
        machineInfo: 'Gépadatok',
        machineGeometryInfo: 'Gépgeometriai adatok',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'IMU-rögzítési adatok',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'Fő GNSS-rögzítési adatok',
        bodyCenterToGnssSub: 'Mellék GNSS-rögzítési adatok',
        extendedModel: 'Kibővített modell',
        measuredValues: 'Kalibrációs adatok',
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'Adatkonverziós hiba',
        system_localization_quantity_error: `Nem regisztrálható több helyadatot tartalmazó fájl.
        Csak egyetlen helyadatot tartalmazó fájlt regisztráljon.`,
        system_detect_0byte_file_error: `Kommunikációs hiba miatt nem sikerült a fájlt regisztrálni a kiszolgálón.
      Regisztrálja újra a fájlt.`,
        system_batch_error: 'Rendszerhiba (P001)',
        system_parameter_error: 'Rendszerhiba (P002)',
        system_parameterfile_notfound: 'Rendszerhiba (P003)',
        system_exception: 'Rendszerhiba (P004)',
        system_logfile_error: 'Rendszerhiba (P005)',
        system_parameterfile_parameter_error: 'Rendszerhiba (P006)',
        desing_localization_error: `Nincsenek elérhető helyadatok. 
      Regisztrálja az alábbi fájlok valamelyikét:
      
        ・GC3-fájl
        ・Vezérlőpontokat tartalmazó TP3-fájl
        ・CAL-fájl`,
        desing_no_support_dxf_error:
          'A DXF-fájl(ok) nem olvasható(ak), mert a verzió régebbi, mint az AutoCAD2000.',
        desing_tile_error: 'Nem sikerült létrehozni a projektfájlt.',
        desing_outline_error: 'Nem sikerült létrehozni a külső határvonalat.',
        desing_area_length_error:
          'A folyamat megszakadt, mert a projektterület túl nagy. Ellenőrizze, hogy a tervadatok és a CAD-adatok léptéke megfelelő-e.',
        desing_layer_name_duplication_error: `Nem regisztrálhatóak duplikált rétegnevet tartalmazó projektadatok.
      Módosítsa a terv rétegnevét, vagy regisztrálja úgy, hogy csak egy fájl tartalmazza ezt a rétegnevet.`,
        targetFiles: '[Fájlnév]',
        targetLayers: '[Réteg]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: 'Nincs',
        system_batch_error: 'Rendszerhiba (T001)',
        system_parameter_error: 'Rendszerhiba (T002)',
        system_parameterfile_notfound: 'Rendszerhiba (T003)',
        system_exception: 'Rendszerhiba (T004)',
        system_logfile_error: 'Rendszerhiba (T005)',
        system_basefile_notfound: 'Rendszerhiba (T006)',
        system_prjfile_multi: 'Rendszerhiba (T007)',
        system_basefile_read_error: 'Rendszerhiba (T008)',
        system_target_layer_notfound: 'Rendszerhiba (T009)',
        system_target_layer_duplication: 'Ez a rétegnév már létezik.',
        system_taskarea_error: 'Érvénytelen munkaterület.',
        system_taskarea_design_notfound:
          'Nincsenek a projektfájlhoz tartozó adatok a munkaterületen (TIN, vonalrendszer, pontok).',
        system_tile_error: 'Nem sikerült létrehozni a projektfájlt.',
        system_outline_error: 'Nem sikerült létrehozni a külső határvonalat',
        system_parameterfile_error: 'Rendszerhiba',
      },
      retrofitError: {
        1000: 'Vezérlő UDP-adatai nem észlelhetők',
        2000: 'Váz IMU nem észlelhető',
        2001: 'Váz IMU hardverhiba',
        2002: 'Váz IMU algoritmushiba',
        2003: 'Váz IMU adathiba',
        2004: 'Váz IMU inicializálási hiba',
        2100: 'Gém IMU nem észlelhető',
        2101: 'Gém IMU hardverhiba',
        2102: 'Gém IMU algoritmushiba',
        2103: 'Gém IMU adathiba',
        2104: 'Gém IMU inicializálási hiba',
        2200: 'Kanálszár IMU nem észlelhető',
        2201: 'Kanálszár IMU hardverhiba',
        2202: 'Kanálszár IMU algoritmushiba',
        2203: 'Kanálszár IMU adathiba',
        2204: 'Kanálszár IMU inicializálási hiba',
        2300: 'Kanál IMU nem észlelhető',
        2301: 'Kanál IMU hardverhiba',
        2302: 'Kanál IMU algoritmushiba',
        2303: 'Kanál IMU adathiba',
        2304: 'Kanál IMU inicializálási hiba',
        2400: 'Dönthető kanál IMU nem észlelhető',
        2401: 'Dönthető kanál IMU hardverhiba',
        2402: 'Dönthető kanál IMU algoritmushiba',
        2403: 'Dönthető kanál IMU adathiba',
        2404: 'Dönthető kanál IMU inicializálási hiba',
        2500: '2. gém IMU nem észlelhető',
        2501: '2. gém IMU hardverhiba',
        2502: '2. gém IMU algoritmushiba',
        2503: '2. gém IMU adathiba',
        2504: '2. gém IMU inicializálási hiba',
        2600: 'Forgásérzékelő nem észlelhető',
        2601: 'Forgásérzékelő rendszerhiba',
        2602: 'Forgásérzékelő hardverhiba',
        2700: 'Eltolásérzékelő nem észlelhető',
        2701: 'Eltolásérzékelő rendszerhiba',
        2702: 'Eltolásérzékelő hardverhiba',
        3000: 'Felső nyomásérzékelő nem észlelhető',
        3100: 'Alsó nyomásérzékelő nem észlelhető',
        4000: 'GNSS-antenna nem észlelhető',
        5000: 'Nem észlelhetők korrekciós adatok',
        5001: 'Nem észlelhető rádióvevő jel',
      },
    },
    dialog: {
      menu: {
        confirmManualLink: 'Átirányítjuk egy másik weboldalra. Elfogadja?',
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'Kanál',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message: 'A vállalati azonosító és a vállalatnév szükséges.',
        },
        save: {
          message1:
            'A vállalati azonosító/vállalatnév nem módosítható, mert tartalmaz',
          message2: 'más Vállalathoz rendelt gépe(ke)t.',
          message3: '',
          message4: 'Ellenőrizze a lenti sorozatszámo(ka)t.',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'Vezérlő',
        },
        undeletable: {
          message: 'A vezérlő használatban van, ezért nem törölhető.',
        },
      },
      Ntrip: {
        save: {
          stop: 'Leállítja ezt az Ntrip-beállítást?',
          delete: 'Törli ezt az Ntrip-beállítást?',
        },
      },
      siteRetrofitList: {
        title: 'Kiegészítő információ',
        message: 'Az építési időszak véget ért.',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: 'Általános beállítás',
          siteSetting: 'Munkaterület-beállítások',
        },
        mapBackgroundColor: 'Térkép háttérszín',
        message:
          'A geokerítés érintésének észlelése/riasztása funkció nem a használat körülményeitől függően nem mindig működik megfelelően. Ne legyen túlzottan magabiztos a funkció használatakor, ismerkedjen meg a funkcióval és a használat körülményeivel.',
      },
      geofenceList: {
        confirmDelete: {
          message: 'Törölni akarja ezt a geokerítést?',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1:
            'A gép egy munkaterülethez van csatolva, ezért nem rendelhető hozzá. Válassza le a gépet a munkaterületről.',
          message2: 'Válassza le a gépet a munkaterületről.',
        },
        sameOwner: {
          message: 'A gép nem rendelhető hozzá, mert vállalata a tulajdonos.',
        },
        unableTransfer: {
          message:
            'A gép áthelyezési jóváhagyásra vár, ezért nem regisztrálható hozzárendeléshez.',
        },
        unableAlternateRegist: {
          message:
            'A gép a vezérlő megbízotti regisztrációja jóváhagyásra vár, ezért nem regisztrálható hozzárendeléshez.',
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          'A gép egy munkaterülethez van csatolva, ezért nem helyezhető át. Válassza le a gépet a munkaterületről.',
        messageRentaled: 'A gép hozzá van rendelve, ezért nem helyezhető át.',
        messageLicense: 'A gép rendelkezik licenccel, ezért nem helyezhető át.',
        pendingApprovals: {
          message1: 'Áthelyezési kérelem fogadva. Várjon a jóváhagyásra.',
          message2:
            'Ha kérdései vannak, vegye fel velünk a kapcsolatot a lenti URL-en keresztül.',
        },
      },
      retrofitSendBacks: {
        submit:
          'Szeretné visszaküldeni a vezérlőt, és a célvezérlőn örökölni a beállításokat?',
        deleteNtrip: 'Szeretné inicializálni a visszaküldött vezérlőt?',
        unableRegion:
          'Ezt a vezérlőt a tengerentúlra szállították. Ha ez a vezérlő a megfelelő, állítsa vissza a „Régiót” null értékre.',
        unableCorporation: {
          message1: 'A vezérlő nem küldhető vissza.',
          message2:
            'Ha úgy véli, ez egy hiba, vegye fel a kapcsolatot kereskedőjével.',
        },
        retrofitRentaled:
          'A vezérlő nem rendelhető hozzá, és nem küldhető vissza.',
        tiedToSite:
          'A gép egy munkaterülethez van csatolva, ezért nem küldhető vissza.',
      },
      retrofitAddToSiteAction: {
        title: 'Kiegészítő információ',
        message:
          'Egy gép jóváhagyásra vár, ezért nem csatolható a munkaterülethez.',
      },
      retrofitAddToSiteActionTransfer: {
        title: 'Kiegészítő információ',
        message:
          'A gép áthelyezés alatt áll, ezért nem csatolható a munkaterülethez.',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message:
            'A kiválasztott Ntrip-beállítás másik gépre kerül átküldésre.',
        },
        unableTransferInfo: {
          message: 'Az áthelyezéshez törölje az alábbi beállításokat.',
          reasonSiteId: 'Munkaterület',
          reasonRentaled: 'Hozzárendelés',
          reasonLicense: 'Licenc',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            'A hozzárendelt vállalat jelenleg egy másik vállalathoz rendeli a gépet. A másik vállalathoz rendelés kezdő dátuma %{date}.',
          message2: 'Válasszon egy %{date} utáni befejezési dátumot.',
        },
        confirmTieUpJobsite: {
          message:
            'A gép egy munkaterülethez van csatolva a hozzárendelt vállalatnál. Befejezi ezt a hozzárendelést?',
        },
        confirmDoubleRental: {
          message:
            'A hozzárendelt vállalat jelenleg egy másik vállalathoz rendeli a gépet. \nA hozzárendelt vállalat jelenleg egy másik vállalathoz rendeli a gépet. Ha beállítja a befejezési időt, az a másik vállalathoz rendelést is befejezi. Beállítja a befejezési dátumot?',
        },
        unapprovedSave: {
          message:
            'Ez a hozzárendelés nem lett jóváhagyva, ezért nem menthető.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Törli ezt a hozzárendelést?',
        },
        approvedDelete: {
          title: '',
          message: 'Ez a hozzárendelés jóvá lett hagyva, ezért nem törölhető.',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message:
            'A gép jelenleg hozzá van rendelve, ezért nem helyezhető át.',
        },
        approvedSave: {
          message: 'A gép rendelkezik licenccel, ezért nem helyezhető át.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Törli ezt az áthelyezést?',
        },
        approvedDelete: {
          title: '',
          message: 'Ez az áthelyezés jóvá lett hagyva, ezért nem törölhető.',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'Kanálszár toldat',
        },
      },
      projectFileUpload: {
        message: 'Nem regisztrálható több vezérlőpontfájl (.gc3/.cal).',
      },
      projectEdit: {
        confirm: {
          message:
            'Amint frissíti a projektet, törölni fogja a meglévő feladat(oka)t. Elvégzi a frissítést?',
        },
      },
      projectConversionStatus: {
        title: 'Átalakítási állapot',
        button: 'OK',
      },
      taskConversionStatus: {
        title: 'Átalakítási állapot',
        button: 'OK',
      },
      taskList: {
        undeletable: {
          message: 'A kiválasztott munka nem törölhető, mert használatban van.',
        },
        unableToCreate: {
          message: 'Nincsenek tervadatok regisztrálva a projektben.',
        },
        unableToDelete: {
          message1:
            'A munka nem törölhető, mert a projekt állapota és a munka állapota a következő.',
          message2: 'Projekt: Átalakítás',
          message3: 'Munka : Hiba',
        },
        unableToUpdate: {
          message1:
            'A munka nem szerkeszthető, mert a projekt állapota és a munka állapota nem a következő.',
          message2: 'Projekt: [Átalakítva]',
          message3: 'Munka : [Áthelyezés][Áthelyezve][Sikertelen áthelyezés]',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'Munka',
        },
        unableToDelete: {
          message: 'A kiválasztott munka nem törölhető, mert használatban van.',
        },
      },
      taskMap: {
        crossingMessage: 'A munkaterület átfedésben van.',
        sameValueMessage: 'Ugyanazokat a koordinátákat adta meg.',
        areaZeroMessage: 'Nincsen terület a munkaterület számára.',
        noPolygonMessage:
          'A munka nem regisztrálható, mert még nincsen regisztrálva.',
      },
      compactionMaterials: {
        add: {
          title: 'Anyag hozzáadása',
        },
        update: {
          title: 'Anyag frissítése',
        },
      },
      weathers: {
        add: {
          title: 'Időjárás hozzáadása',
        },
        update: {
          title: 'Időjárás frissítése',
        },
      },
      operators: {
        add: {
          title: 'Gépkezelő hozzáadása',
        },
        update: {
          title: 'Gépkezelő frissítése',
        },
      },
      compactionWorkAreas: {
        update: {
          message: 'Tömörítési munkaterület szerkesztése',
        },
        undeletable: {
          message:
            'Az építési területen lévő tömörítési réteg használatban van, ezért nem törölhető',
        },
      },
      compactionLayers: {
        undeletable: {
          message: 'A tömörítési réteg használatban van, ezért nem törölhető',
        },
        deleteConfirm: {
          messageTargetItem: 'Tömörítési réteg',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message:
            'Egy másik Ntrip-beállítás kerül átküldésre a kiválasztott gépre.',
        },
        confirmDelete: {
          message: 'Törli ezt az Ntrip-beállítást?',
        },
        confirmDeleteWithMachine: {
          message:
            'A gép jelenleg csatolva van. Törli ezt az Ntrip-beállítást?',
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'Értesítés',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: 'Állítsa be a licence(ke)t az alábbi géphez.',
        },
        changeDisable: {
          message:
            'Módosítsa az alábbi építőipari gépek licencazonosítóját érvényesre.',
        },
        changeTemporary: {
          message:
            'Állítsa be a licence(ke)t az alábbi géphez. A jelenleg beállított átmeneti licenc érvénytelenítve lesz.',
        },
        errorAdd: {
          message:
            'A gép licence nem módosítható, mert már az Összes licenc be lett állítva.',
        },
        errorSameType: {
          message:
            'A gép licence nem módosítható, mert már be lett állítva azonos típusú licenc.',
        },
        errorUnlimited: {
          message:
            'A géphez már Korlátlan licenc van beállítva, további licencek nem állíthatóak be.',
        },
        addAll: {
          message:
            'Az alábbi géphez már van(nak) beállítva licenc(ek). Beállítja az Összes licencet?',
        },
        compare: {
          message: 'Módosítsa a licence(ke)t az alábbi géphez.',
        },
        warning: {
          message:
            'A(z) %{id} %{retrofit} licenccel rendelkezik. Leválasztja a licencet?',
        },
        unableTransfer: {
          message: 'A gép áthelyezés alatt áll, ezért a licenc nem csatolható.',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial:
            'Rendszerhiba. Az adatok alapján nem sikerült elemezni a képet.',
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1: 'Regisztrációs kérelem fogadva. Várja meg a jóváhagyást.',
          message2:
            'Ha kérdései vannak, vegye fel velünk a kapcsolatot a lenti URL-en keresztül.',
        },
        corporationIdCheck: {
          message1:
            'A vezérlő már regisztrálva lett. Ellenőrizze a gépek közti kereséssel.',
          message2:
            'Ha nem találja a célvezérlőt a listában, vegye fel velünk a kapcsolatot a lenti URL-en keresztül.',
        },
        earthbrainIdCheck: {
          message1: 'Elnézést kérünk a kényelmetlenségért,',
          message2:
            'kérjük, vegye fel velünk a kapcsolatot a lenti URL-en keresztül az alábbi információval.',
          message3: '-Vezérlő sorozatszáma',
          message4: '-Regisztrálandó gép neve (választható)',
          message5: '-Regisztrálandó vállalati azonosító',
        },
        invalidSerialNumber: {
          message1: 'A vezérlőt más egy másik vállalat használja.',
          message2: 'Ha más okból szeretné cserélni, ',
          message3:
            'kérjük, vegye fel velünk a kapcsolatot a lenti URL-en keresztül az alábbi információval.',
          message4: '-Vezérlő sorozatszáma',
          message5: '-Regisztrálandó gép neve (választható)',
          message6: '-Regisztrálandó vállalati azonosító',
          message7: '-A csere oka',
        },
        lendingRetrofits: {
          message1: 'A vezérlő hozzá van rendelve.',
          message2:
            'Alkalmazza újra, miután a hozzárendelés véget ért a hozzárendelő vállalatnál.',
          message3:
            'Ha kérdései vannak, vegye fel velünk a kapcsolatot a lenti URL-en keresztül.',
        },
        siteAttached: {
          message1: 'A vezérlő egy munkaterülethez van csatolva.',
          message2:
            'Alkalmazza újra, miután leválasztotta a gépet a munkaterületről.',
          message3:
            'Ha kérdései vannak, vegye fel velünk a kapcsolatot a lenti URL-en keresztül.',
        },
        serialNumberExistError: {
          message1:
            'Ez a sorozatszám nem létező vezérlőhöz tartozik. Ellenőrizze, hogy a vezérlő megadott sorozatszáma helyes-e.',
          message2:
            'Ha a probléma továbbra is fennáll, vegye fel velünk a kapcsolatot a lenti URL-en keresztül.',
        },
        ntripShortage: {
          message1:
            'Nem sikerült kiállítani az Ntrip felhasználóazonosítóját és jelszavát.',
          message2: 'Vegye fel velünk a kapcsolatot a lenti URL-en keresztül.',
        },
        serialnumberCheck: {
          message: 'Ellenőrizze a termékeket az alábbi sorozatszámokkal',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: 'retrofitAlternateRegists',
        },
        saveValidation: {
          message: 'Az e-mail-cím szükséges.',
        },
        pendingApprovals: {
          message1: 'Regisztrációs kérelem fogadva. Várja meg a jóváhagyást.',
          message2:
            'Ha kérdései vannak, vegye fel velünk a kapcsolatot a lenti URL-en keresztül.',
        },
        corporationIdCheck: {
          message1:
            'A vezérlő már regisztrálva lett. Ellenőrizze a gépek közti kereséssel.',
          message2:
            'Ha nem találja a célvezérlőt a listában, vegye fel velünk a kapcsolatot a lenti URL-en keresztül.',
        },
        earthbrainIdCheck: {
          message1: 'Elnézést kérünk a kényelmetlenségért,',
          message2:
            'kérjük, vegye fel velünk a kapcsolatot a lenti URL-en keresztül az alábbi információval.',
          message3: '-Vezérlő sorozatszáma',
          message4: '-Regisztrálandó gép neve (választható)',
          message5: '-Regisztrálandó vállalati azonosító',
        },
        invalidSerialNumber: {
          message1: 'A vezérlőt más egy másik vállalat használja.',
          message2: 'Ha más okból szeretné cserélni, ',
          message3:
            'kérjük, vegye fel velünk a kapcsolatot a lenti URL-en keresztül az alábbi információval.',
          message4: '-Vezérlő sorozatszáma',
          message5: '-Regisztrálandó gép neve (választható)',
          message6: '-Regisztrálandó vállalati azonosító',
          message7: '-A csere oka',
        },
        lendingRetrofits: {
          message1: 'A vezérlő hozzá van rendelve.',
          message2:
            'Alkalmazza újra, miután a hozzárendelés véget ért a hozzárendelő vállalatnál.',
          message3:
            'Ha kérdései vannak, vegye fel velünk a kapcsolatot a lenti URL-en keresztül.',
        },
        siteAttached: {
          message1: 'A vezérlő egy munkaterülethez van csatolva.',
          message2:
            'Alkalmazza újra, miután leválasztotta a gépet a munkaterületről.',
          message3:
            'Ha kérdései vannak, vegye fel velünk a kapcsolatot a lenti URL-en keresztül.',
        },
        serialNumberExistError: {
          message1:
            'Ez a sorozatszám nem létező vezérlőhöz tartozik. Ellenőrizze, hogy a vezérlő megadott sorozatszáma helyes-e.',
          message2:
            'Ha a probléma továbbra is fennáll, vegye fel velünk a kapcsolatot a lenti URL-en keresztül.',
        },
        ntripShortage: {
          message1:
            'Nem sikerült kiállítani az Ntrip felhasználóazonosítóját és jelszavát.',
          message2: 'Vegye fel velünk a kapcsolatot a lenti URL-en keresztül.',
        },
        serialnumberCheck: {
          message: 'Ellenőrizze a termékeket az alábbi sorozatszámokkal',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1: 'A vezérlő már az Ön vállalata tulajdonában van.',
          message2: 'Vegye fel a kapcsolatot a jelentkező vállalattal.',
        },
        userCorporation: {
          message1:
            'Nem hagyhatja jóvá ezt a kérelmet, mert már kölcsönadta ezt a vezérlőt.',
          message2: 'Vegye fel a kapcsolatot a jelentkező vállalattal.',
        },
      },
      licensePurchaseNotice: {
        button: 'OK',
        message1: `Használatban van egy gép a területen licenc nélkül.
      Licenc szükséges a területen történő használathoz.
      Ha nem vásárolt licencet, vásároljon egyet a Smart Construction Marketplace-en.`,
        message2: `
      Ha másik vállalattól bérel, kérjen egy licencet a géphez a bérbeadótól.
      
      Ha kérdései vannak, tekintse át az alábbi ügyfélszolgálati oldalt.`,
      },
      siteBuriedStructureEdit: {
        title: 'Eltemetett szerkezet',
      },
    },
    tootip: {
      retrofitShareFileList: 'Alkalmazási napló',
      retrofitConfigList: 'Konfigurációs információ',
      retrofitKitInfoList: 'Vezérlőadatok',
      retrofitAccuraciesList: 'Pontosságellenőrzési eredmény',
      retrofitCalibaList: 'Kalibrációs adatok',
      retrofitBasicSettingList: 'Alapbeállítás',
      retrofitErrorList: 'Hiba részletei',
      retrofitTopographicSurveyList: 'Topográfiai felmérés',
      retrofitAsbuilts: 'Építési eredmények',
    },
    actions: {
      ok: 'OK',
      add: 'Hozzáadás',
      create: 'Regisztráció',
      yes: 'Igen',
      no: 'Nem',
      confirmation: 'Megerősítés',
      save: 'Mentés',
      cancel: 'Törlés',
      required: 'Szükséges',
      addMachineToSite: 'Gép munkaterülethez adása',
      addBucketToSite: 'Hozzáadás',
      deleteFromSite: 'Leválasztás a munkaterületről',
      removeLicense: 'Leválasztás a gépről',
      lendMachine: 'Hozzárendelés',
      transferMachine: 'Áthelyezés',
      remoteSupport: 'Távoli támogatás',
      lendBucket: 'Megosztás',
      approval: 'Jóváhagyás',
      connect: 'Csatlakozás',
      creationInstructions: 'Építési eredmény lekérése',
      csvDownload: 'Letöltés',
      retrofitBundleCreate: 'Kötegelt regisztráció',
      termsAccept: 'Egyetértek',
      termsDeny: 'Nem értek egyet',
      bulkRegistration: 'Kötegelt frissítés',
      bulkRegistrationOK: 'OK',
      compactionItems: 'Tömörítési tételek',
      addLanguage: 'Nyelv hozzáadása',
    },
    message: {
      error: 'Hiba történt',
      confirm: 'Elfogadja?',
      fileUpload: 'Húzza ide a fájlokat.',
      success: 'A regisztráció kész',
      approved: 'A jóváhagyás kész',
      deleteConfirmTitle: '',
      deleteConfirmContent: '%{title} törlése?',
      bulkDeleteConfirmContent: '%{title} törlése?',
      disconnectFromSite: 'Leválasztja a munkaterületről?',
      saveConfirmContent: 'Megerősítés',
      removeLicense: 'Leválasztja a gépről?',
      task: {
        crossingMessage: 'A munkaterület átfedésben van.',
        sameValueMessage: 'Ugyanazokat a koordinátákat adta meg.',
        areaZeroMessage: 'Nincsen terület a munkaterület számára.',
        invalidPolygonMessage: 'Ugyanazokat a koordinátákat adta meg.',
      },
      geofence: {
        crossingMessage: 'A geokerítés területe átfedésben van.',
        sameValueMessage: 'Ugyanazokat a koordinátákat adta meg.',
        areaZeroMessage: 'Nincsen terület a geokerítés területe számára.',
      },
      licenseError:
        'Nem rendelkezik Smart Construction Pilot (webes alkalmazás) licenccel.',
      retrofitsManage: {
        serialNumberMatchError: 'A sorozatszám nem egyezik.',
        serialNumberInputError: 'A sorozatszám nem létezik.',
        invalidAccess:
          'Nincsen engedélye az oldal megtekintéséhez, térjen vissza a legfelső oldalra.',
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'A sorozatszám nem egyezik.',
        serialNumberInputError: 'A sorozatszám nem létezik.',
        retrofitRentaled:
          'A vállalati adatok nem frissíthetőek, amíg a vezérlő hozzá van rendelve.',
        invalidCorporation:
          'Mivel vállalata nem forgalmazó, nem végezheti el egy vezérlő megbízotti regisztrációját.',
      },
    },
    validation: {
      startDate: 'Kezdési dátum',
      endDate: 'Befejezési dátum',
      invalidDateFormat: 'A dátum formátuma érvénytelen.',
      invalidDateTimeFormat: 'A dátum és az idő formátuma érvénytelen.',
      duplicateProject: 'A projektnév duplikálva van.',
      incorrectBeforeDate:
        '%{target} korábbra kell beállítani, mint %{before}.',
      incorrectAfterDate: '%{target} későbbre kell beállítani, mint %{after}.',
      incorrectPlanStartDate:
        'Az ütemezett kezdési dátumnak az ütemezett befejezési dátumnál korábbinak kell lennie.',
      incorrectPlanEndDate:
        'Az ütemezett befejezési dátumnak az ütemezett kezdési dátumnál későbbinek kell lennie.',
      incorrectActualStartDate:
        'A kezdési dátumot a befejezési dátumnál korábbra kell beállítani.',
      incorrectActualEndDate:
        'A befejezési dátumot a kezdési dátumnál későbbre kell beállítani.',
      withActualStartDate: 'A kezdési dátumot is adja meg.',
      inputAvailableDate: 'A legkorábbi megadható dátum %{date}.',
      registrationRequired:
        'Válassza ki a hozzárendelt vállalatot vagy e-mail-címet.',
      bothInput:
        'Csak a hozzárendelt vállalatot vagy az e-mail-címet adhatja meg.',
      supportMode:
        'Adjon meg vállalati azonosítót vagy munkaterület-azonosítót.',
      invalidBkFile: 'Ez nem BK3-fájl.',
      invalidPdfFile: 'Ez nem PDF-fájl.',
      duplicatedBucketName: 'A kanálnév duplikálva van.',
      duplicatedTaskName: 'A munkanév duplikálva van.',
      duplicateGeofenceName: 'A geokerítésnév duplikálva van.',
      duplicatedCompactionWorkAreaName: 'Duplikált tömörítésimunkaterület-név',
      duplicatedCompactionLayerName: 'Duplikált tömörítésiréteg-név',
      duplicated: {
        compactionMaterials: {
          message: 'Duplikált anyagnév',
        },
        weathers: {
          message: 'Duplikált időjárásnév',
        },
        operators: {
          message: 'Duplikált gépkezelőnév',
        },
      },
      containInvalidString:
        'Érvénytelen karaktereket tartalmaz. (%{msg} nem engedélyezett)',
      validHalfWidthAlphanumeric:
        'Csak félszélességű alfanumerikus karakterek engedélyezettek.',
      validNumberFormat: 'Nem szám.',
      validIntegerFormat: 'Nem egész.',
      validIntegerRangeFormat: '0 és 2147483647 közötti érték adható meg.',
      validDuplicateLanguage: 'Ugyanaz a nyelv van kiválasztva.',
      validBeforeDate:
        '%{target} korábbi dátumra és időre kell beállítani, mint %{before}.',
      validAfterDate:
        '%{target} későbbi dátumra és időre beállítani, mint %{after}.',
    },
  },
  resources: {
    sites: {
      name: 'Munkaterület',
      fields: {
        name: 'Munkaterület',
        corporationName: 'Cég neve',
        status: 'Állapot',
        workPeriodStartDate: 'Kezdési dátum',
        workPeriodEndDate: 'Befejezési dátum',
      },
      status: {
        completed: 'Kész',
        waiting: 'Mielőtt az építkezés megkezdődik',
        working: 'Építkezés folyamatban',
      },
    },
    retrofits: {
      name: 'Gép',
      fields: {
        information: 'Gépadatok',
        rental: 'Hozzárendelés',
        sendBack: 'Visszaküldés',
        corporationId: 'Használt vállalat',
        siteId: 'Munkaterület',
        uuid: 'UUID',
        isError: 'Állapot',
        isOnline: 'Online',
        lastOnlineTime: 'Utolsó kapcsolat',
        basicInfoManufacturer: 'Gyártó',
        basicInfoModel: 'Modell',
        basicInfoProductNumber: 'Termék sz.',
        basicInfoSerialNumber: 'Sorozatszám',
        controllerManufacturer: 'Gyártó',
        controllerModel: 'Modell',
        controllerFirmwareVersion: 'Firmware-verzió',
        gnssReceiverMainManufacturer: 'Gyártó',
        gnssReceiverMainModel: 'Modell',
        gnssReceiverMainFirmwareVersion: 'Firmware-verzió',
        gnssReceiverSubManufacturer: 'Gyártó',
        gnssReceiverSubModel: 'Modell',
        gnssReceiverSubFirmwareVersion: 'Firmware-verzió',
        machineInfoCompanyName: 'Gyártmányok',
        machineInfoMachineType: 'Gép típusa',
        machineInfoMachineName: 'Gép neve',
        machineInfoMachineId: 'Gépazonosító',
        rentalStatus: 'Hozzárendelés',
        lastUpdated: 'Utoljára frissítve',
      },
      isError: {
        true: 'Vezérlőállapot értesítési formátum érvénytelen',
        false: 'Normális',
      },
      isOnline: {
        true: 'Online',
        false: 'Offline',
      },
      machineInfoMachineType: {
        excavator: 'Kotrógép',
        roadroller: 'Úthenger',
        dozer: 'Dózer',
        rigiddump: 'Merev dömper',
        wheelloader: 'Kerekes rakodó',
        grader: 'Földgyalu',
        automobile: 'Automobil',
      },
      licenseMachineType: {
        excavator: 'Kotrógép',
        roadroller: 'Úthenger',
        dozer: 'Dózer',
        rigiddump: 'Merev dömper',
        wheelloader: 'Kerekes rakodó',
        grader: 'Földgyalu',
        automobile: 'Automobil',
        excavator_license: 'Kotrógép: %{licenseGrantInfo}',
        roadroller_license: 'Úthenger: %{licenseGrantInfo}',
        dozer_license: 'Dózer: %{licenseGrantInfo}',
        rigiddump_license: 'Merev dömper: %{licenseGrantInfo}',
        wheelloader_license: 'Kerekes rakodó: %{licenseGrantInfo}',
        grader_license: 'Földgyalu: %{licenseGrantInfo}',
        automobile_license: 'Automobil: %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: 'Áthelyezés',
        sent: 'Áthelyezve',
        failtosend: 'Áthelyezés sikertelen',
      },
      rentalStatus: {
        approved: 'Jóváhagyva',
        unapproved: 'Nincs jóváhagyva',
        returned: 'Visszaküldve',
      },
      controllerStatusCode: {
        version: {
          1: '',
          2: '',
        },
        ahrsEstimateStatus: {
          0: '',
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
        },
        positionStatus: {
          0: '',
          1: '',
          2: '',
          3: '',
          4: 'GNSS-antenna nem észlelhető',
        },
        baseStationConnectionStatus: {
          0: '',
          1: '',
          2: '',
        },
        boomImuStatus: {
          0: '',
          1: 'Gém IMU nem észlelhető',
          2: 'Gém IMU nem észlelhető',
          3: 'Gém IMU nem észlelhető',
        },
        armImuStatus: {
          0: '',
          1: 'Kanálszár IMU nem észlelhető',
          2: 'Kanálszár IMU nem észlelhető',
          3: 'Kanálszár IMU nem észlelhető',
        },
        bucketImuStatus: {
          0: '',
          1: 'Kanál IMU nem észlelhető',
          2: 'Kanál IMU nem észlelhető',
          3: 'Kanál IMU nem észlelhető',
        },
        bodyImuStatus: {
          0: '',
          1: 'Váz IMU nem észlelhető',
          2: 'Váz IMU nem észlelhető',
          3: 'Váz IMU nem észlelhető',
        },
        boomHeadPressureSensorStatus: {
          0: '',
          1: 'Felső nyomásérzékelő nem észlelhető',
          2: 'Felső nyomásérzékelő nem észlelhető',
          3: 'Felső nyomásérzékelő nem észlelhető',
        },
        boomBottomPressureSensorStatus: {
          0: '',
          1: 'Alsó nyomásérzékelő nem észlelhető',
          2: 'Alsó nyomásérzékelő nem észlelhető',
          3: 'Alsó nyomásérzékelő nem észlelhető',
        },
        tiltBucketImuStatus: {
          0: '',
          1: 'Dönthető kanál IMU nem észlelhető',
          2: 'Dönthető kanál IMU nem észlelhető',
          3: 'Dönthető kanál IMU nem észlelhető',
        },
        secondBoomImuStatus: {
          0: '',
          1: '2. gém IMU nem észlelhető',
          2: '2. gém IMU nem észlelhető',
          3: '2. gém IMU nem észlelhető',
        },
        rotarySensorStatus: {
          0: '',
          1: 'Eltolásérzékelő nem észlelhető',
          2: 'Eltolásérzékelő nem észlelhető',
          3: 'Eltolásérzékelő nem észlelhető',
          4: 'Eltolásérzékelő nem észlelhető',
          5: 'Eltolásérzékelő nem észlelhető',
          6: 'Eltolásérzékelő nem észlelhető',
        },
        boomOffsetSensorStatus: {
          0: '',
          1: 'Forgásérzékelő nem észlelhető',
          2: 'Forgásérzékelő nem észlelhető',
          3: 'Forgásérzékelő nem észlelhető',
          4: 'Forgásérzékelő nem észlelhető',
          5: 'Forgásérzékelő nem észlelhető',
          6: 'Forgásérzékelő nem észlelhető',
        },
        tiltrotatorStatus: {
          0: '',
          1: 'Nem észlelhető döntő-forgató',
          2: 'Nem észlelhető döntő-forgató',
          3: 'Nem észlelhető döntő-forgató',
        },
      },
    },
    retrofitRentals: {
      name: 'Hozzárendelés',
      fields: {
        id: 'ID',
        retrofitId: 'Gépazonosító',
        fromCorporationId: 'Hozzárendelő vállalat azonosítója',
        fromCorporationName: 'Hozzárendelő vállalat',
        toCorporationId: 'Hozzárendelt vállalat',
        toCorporationName: 'Használt vállalat',
        retrofitRentalId: 'Hozzárendelési azonosító',
        isApproved: 'Állapot',
        isReturned: 'Visszaküldve',
        planStartDate: 'Ütemezett kezdési dátum',
        planEndDate: 'Ütemezett befejezési dátum',
        actualStartDate: 'Kezdési dátum',
        actualEndDate: 'Befejezési dátum',
        rentalUsers: 'E-mail',
        email: 'E-mail',
        rentalStatus: 'Állapot',
      },
      isApproved: {
        true: 'Jóváhagyva',
        false: 'Nincs jóváhagyva',
      },
      rentalStatus: {
        approved: 'Jóváhagyva',
        unapproved: 'Nincs jóváhagyva',
        returned: 'Visszaküldve',
      },
    },
    pendingRentals: {
      name: 'Jóváhagyásra vár',
      fields: {
        fromCorporationName: 'Hozzárendelő vállalat',
        planStartDate: 'Ütemezett kezdési dátum',
        planEndDate: 'Ütemezett befejezési dátum',
      },
    },
    pendingTransfers: {
      name: 'Jóváhagyásra vár',
      fields: {
        fromCorporationName: 'Áthelyező vállalat',
        planDate: 'Ütemezett áthelyezési dátum',
        lastUpdated: 'Utoljára frissítve',
      },
    },
    pendingAlternateRegists: {
      name: 'Jóváhagyásra vár',
      fields: {
        fromCorporationName: 'Jelenlegi tulajdonság',
        lastUpdated: 'Utoljára frissítve',
      },
    },
    assignments: {
      name: 'Áthelyezés',
      fields: {
        id: 'ID',
        retrofitId: 'Gépazonosító',
        fromCorporationId: 'Áthelyező vállalat azonosítója',
        fromCorporationName: 'Áthelyező vállalat',
        toCorporationId: 'Fogadó vállalat',
        toCorporationName: 'Vállalat',
        isApproved: 'Állapot',
        planDate: 'Ütemezett áthelyezési dátum',
        actualDate: 'Áthelyezési dátum',
        transferUsers: 'E-mail',
        email: 'E-mail',
        lastUpdated: 'Utoljára frissítve',
      },
      isApproved: {
        true: 'Jóváhagyva',
        false: 'Nincs jóváhagyva',
      },
    },
    buckets: {
      name: 'Kanál',
      fields: {
        companyName: 'Cég neve',
        siteId: 'Munkaterület',
        name: 'Név',
        corporationId: 'Használt vállalat',
        bucketType: 'Típus',
        bk3File: 'BK3-fájl',
        'bk3File.title': 'BK3-fájl',
        gltfFile: 'Kanálfájl',
        uploadedBy: 'Regisztrálta',
        updatedBy: 'Frissítette',
        fileStatus: 'Állapot',
        lastUpdated: 'Utoljára frissítve',
        uploadBk: 'BK3 feltöltése',
      },
      bucketType: {
        slope: 'Rézsűző',
        standard: 'Standard',
        tilt: 'Dönthető',
        tiltrotator: 'Döntő-forgató',
      },
      uploadedBy: {
        admin: 'Rendszergazda',
        tablet: 'Táblagép',
      },
      fileStatus: {
        converting: 'Átalakítás',
        completed: 'Kész',
        error: 'Hiba',
      },
    },
    projects: {
      name: 'Projekt',
      fields: {
        name: 'Projektnév',
        coordinate: 'Koordinátarendszer',
        version: 'Version',
        status: 'Állapot',
        lastUpdated: 'Utoljára frissítve',
        regionId: 'Régió',
        projectionId: 'Leképezés',
        geoidId: 'Geoid',
        datumId: 'Dátum',
        file: 'Fájl(ok)',
        retrofitId: 'Célgép',
      },
      status: {
        converted: 'Kész',
        waitforconvert: 'Átalakítás',
        error: 'Hiba',
      },
    },
    extensionarms: {
      name: 'Kanálszár toldat',
      fields: {
        extensionarmType: 'Típus',
        name: 'Név',
        corporationId: 'Használt vállalat',
        corporationName: 'Használt vállalat',
        siteId: 'Munkaterület',
        siteName: 'Munkaterület',
        lastUpdated: 'Utoljára frissítve',
        extensionarmFile: 'Kanálszár toldat fájl',
      },
      extensionarmType: {
        basic: 'Kanálszár toldat',
        a: '„A” típus',
        b: '„B” típus',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: 'Megosztás ide',
        name: 'Név',
        extensionarmType: 'Típus',
        extensionarmFile: {
          title: 'Kanálszár toldat fájl',
        },
      },
    },
    corporations: {
      name: 'Vállalat',
      fields: {
        name: 'Használt vállalat',
      },
    },
    retrofitShareFiles: {
      name: 'Alkalmazási napló',
      fields: {
        name: 'Fájlnév',
        fileTime: 'Fogadás dátuma és ideje',
        url: 'Letöltés',
      },
    },
    retrofitConfigs: {
      name: 'Konfigurációs információ',
      fields: {
        timeStamp: 'Fogadás dátuma és ideje',
        url: 'Letöltés',
      },
    },
    retrofitKitInfos: {
      name: 'Vezérlőadatok',
      fields: {
        timeStamp: 'Fogadás dátuma és ideje',
        url: 'Letöltés',
      },
    },
    retrofitAccuracies: {
      name: 'Pontosságellenőrzési eredmény',
      fields: {
        checkDateTime: 'Megerősítés dátuma és ideje',
        surveyPoint: 'Mérési pont',
        positionN: 'Mért érték: N',
        positionE: 'E',
        positionZ: 'Z',
        machineOffsetN: 'Eltolási érték: N',
        machineOffsetE: 'E',
        machineOffsetZ: 'Z',
        referencePointName: 'Referenciapont neve',
        referencePointN: 'Referenciapont: N',
        referencePointE: 'E',
        referencePointZ: 'Z',
        unitLength: 'Hosszmértékegység',
      },
    },
    retrofitCalibs: {
      name: 'Gépkalibrálási információ részletei',
      fields: {
        timeStamp: 'Fogadás dátuma és ideje',
        url: 'Letöltés',
        detailInfo: {
          createDateTime: 'Létrehozás dátuma és ideje',
          updateDateTime: 'Frissítés dátuma és ideje',
          machineInfo: {
            companyName: 'Gyártmányok',
            machineType: 'Gép típusa',
            machineName: 'Gép neve',
            machineId: 'Gépazonosító',
            drumConfiguration: 'Gépkonfiguráció',
            guidanceMode: 'Navigációs üzemmód',
          },
          machineGeometryInfo: {
            boomLength: 'Gém hossza',
            secondBoomLength: '2. gém hossza',
            armLength: 'Kanálszár hossza',
            armTopPinToBucketSideLinkPinLength:
              'Távolság a kanálszár teteje és a kanál oldalsó forgócsapja között',
            boomTopPinToBucketSideLinkPinLength:
              'Távolság a gém teteje és a kanál oldalsó forgócsapja között',
            bucketLink1Length:
              'Távolság a kanál oldala és a kanál munkahengere között',
            bucketLink2Length:
              'Távolság a távolság a kanál munkahengere és a kanál forgócsapja között',
            bodyCenterToBoomFootPin: {
              x: 'X gémtőcsap távolsága a vázközéptől',
              y: 'Y gémtőcsap távolsága a vázközéptől',
              z: 'Z gémtőcsap távolsága a vázközéptől',
            },
            drumWidth: 'Tolólap szélessége',
            length: 'Lánctalplemezek közti távolság',
            shoeInterval: 'Lánctalplemezek osztásköze',
            bladeWidthForDozer: 'Dózer tolólapjának szélessége',
            distanceFromBodyToBlade: 'Váz és tolólap távolsága',
            frontToMainGnssAntenna:
              'Távolság az elülső földdel érintkező rész végeit összekötő egyenes vonal közepe és a fő X GNSS-antenna között',
            frontToSubGnssAntenna:
              'Távolság az elülső földdel érintkező rész végeit összekötő egyenes vonal közepe és a mellék X GNSS-antenna között',
            centerToMainGnssAntenna:
              'Y távolság a középpont és a fő GNSS elektromos fázisának központja között',
            centerToSubGnssAntenna:
              'Y távolság a középpont és a mellék GNSS elektromos fázisának központja között',
            groundToMainGnssAntenna:
              'Z távolság a talaj és a fő GNSS elektromos fázisának központja között',
            groundToSubGnssAntenna:
              'Z távolság a talaj és a mellék GNSS elektromos fázisának központja között',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'Gém IMU eltolási szöge',
            secondBoomImuOffsetAngle: '2. gém IMU eltolási szöge',
            armImuOffsetAngle: 'Kanálszár IMU eltolási szöge',
            bucketImuOffsetAngle: 'Kanál IMU eltolási szöge',
            bodyImuOffsetRollAngle: 'Váz IMU dőlésszögeltolás',
            bodyImuOffsetPitchAngle: 'Váz IMU bólintásiszög-eltolás',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: 'Fő X GNSS távolsága a vázközéptől',
                y: 'Fő Y GNSS távolsága a vázközéptől',
                z: 'Fő Z GNSS távolsága a vázközéptől',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: 'Mellék X GNSS távolsága a vázközéptől',
                y: 'Mellék Y GNSS távolsága a vázközéptől',
                z: 'Mellék Z GNSS távolsága a vázközéptől',
              },
            },
          },
          extendedModel: {
            swingBoom: 'Forgó gémes modell',
            twoPieceBoom: '2 részes gémes modell',
            minimalSwingRadius: 'Eltolt gémes modell',
          },
          measuredValues: {
            otherLength: {
              machineHeight: 'Ellensúly hézag',
            },
            bodyImu1: {
              roll: 'IMU mért érték dőlése',
              pitch: 'IMU mért érték bólintása',
              yaw: 'IMU mért érték oldalkitérése',
            },
            bodyImu2: {
              roll: 'IMU mért érték dőlése',
              pitch: 'IMU mért érték bólintása',
              yaw: 'IMU mért érték oldalkitérése',
            },
            frontLeft: {
              x: 'Bal elülső X mért értéke',
              y: 'Bal elülső Y mért értéke',
              z: 'Bal elülső Z mért értéke',
            },
            frontRight: {
              x: 'Jobb elülső X mért értéke',
              y: 'Jobb elülső Y mért értéke',
              z: 'Jobb elülső Z mért értéke',
            },
            frontGroundingEnd: {
              x: 'Elülső földdel érintkező vég X mért értéke',
              y: 'Elülső földdel érintkező vég Y mért értéke',
              z: 'Elülső földdel érintkező vég Z mért értéke',
            },
            rearGroundingEnd: {
              x: 'Hátsó földdel érintkező vég X mért értéke',
              y: 'Hátsó földdel érintkező vég Y mért értéke',
              z: 'Hátsó földdel érintkező vég Z mért értéke',
            },
            mainGnssAntenna: {
              x: 'Fő GNSS-antenna X mért értéke',
              y: 'Fő GNSS-antenna Y mért értéke',
              z: 'Fő GNSS-antenna Z mért értéke',
            },
            subGnssAntenna: {
              x: 'Mellék GNSS-antenna X mért értéke',
              y: 'Mellék GNSS-antenna Y mért értéke',
              z: 'Mellék GNSS-antenna Z mért értéke',
            },
            prismPoleOffset: 'Prizmarúd magassága',
            antennaElectricalCenterHeight:
              'Magasság az antenna elektromos központjától',
          },
        },
      },
    },
    retrofitBasicSettings: {
      name: 'Alapbeállítás',
      fields: {
        timeStamp: 'Fogadás dátuma és ideje',
        url: 'Letöltés',
      },
    },
    retrofitErrors: {
      name: 'Hibainformációk',
      fields: {
        notifiedTime: 'Előfordulás dátuma és ideje',
        errorCodes: 'Hibakód: Részletek',
      },
    },
    retrofitToposurveys: {
      name: 'Topográfiai felmérési lista',
      fields: {
        surveyDate: 'Mérés dátuma és ideje',
        projectName: 'Projektnév',
        layerName: 'Réteg',
        pointName: 'Pont neve',
        surveyPoint: 'Mérési pont',
        n: 'Mért érték: N',
        e: 'E',
        z: 'Z',
        unitLength: 'Hosszmértékegység',
      },
    },
    asbuilts: {
      name: 'Építési eredmények',
      fields: {
        execStartDate: 'Végrehajtás kezdete',
        execEndDate: 'Végrehajtás vége',
        startPeriod: 'Beszerzési időszak kezdete',
        endPeriod: 'Beszerzési időszak vége',
        execStatus: 'Állapot',
        url: '',
        csvType: 'Adatformátum',
        createUser: 'Felhasználó létrehozása',
      },
      execStatus: {
        complete: 'Kész',
        inprogress: 'Feldolgozás',
        error: 'Hiba',
        completewithoutdata: 'Kész (nincs adat)',
      },
    },
    asbuiltTime: {
      fields: {
        id: 'Idő beállítva',
      },
    },
    retrofitBasicInfos: {
      name: 'Alapadatok',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: 'Forgalmazó',
        retrofitRegionId: 'Régió',
        corporationId: 'Vállalati azonosító',
        corporationName: 'Cég neve',
        basicInfoManufacturer: 'Gyártó',
        basicInfoModel: 'Modell',
        basicInfoProductNumber: 'Termék sz.',
        basicInfoSerialNumber: 'Sorozatszám',
        lastUpdated: 'Utoljára frissítve',
        description: 'Leírás',
        ownerId: 'Vállalati azonosító',
        ownerName: 'Cég neve',
      },
      tag: {
        retrofitBasicInfosDetail: 'Alapadatok',
        sendBack: 'Visszaküldés',
      },
      sendBack: {
        fields: {
          site: 'Munkaterület',
          corporation: 'Használt vállalat',
          ntripSettingInfo: 'Ntrip-azonosító',
          toRetrofit: 'Alternatív vezérlő',
          notificationEmailAddress: 'E-mail',
        },
      },
    },
    retrofitBulkActions: {
      name: 'Kötegelt regisztráció',
      fields: {
        uploadFileName: 'Fájlnév',
        execStartDate: 'Végrehajtás kezdési dátuma és ideje',
        execEndDate: 'Végrehajtás befejezési dátuma és ideje',
        execStatus: 'Állapot',
        uploadFile: 'Regisztrált fájl',
        resultFile: 'Regisztrálási eredmények',
      },
      execStatus: {
        complete: 'Kész',
        inprogress: 'Feldolgozás',
        error: 'Hiba',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: 'AdminCompanyBulkAction',
      fields: {
        uploadFileName: 'Fájlnév',
        execStartDate: 'Végrehajtás kezdési dátuma és ideje',
        execEndDate: 'Végrehajtás befejezési dátuma és ideje',
        execStatus: 'Állapot',
        uploadFile: 'Regisztrált fájl',
        resultFile: 'Regisztrálási eredmények',
      },
      execStatus: {
        complete: 'Kész',
        inprogress: 'Feldolgozás',
        error: 'Hiba',
      },
    },
    tasks: {
      name: 'Munka',
      fields: {
        name: 'Név',
        status: 'Állapot',
        description: 'Leírás',
        projectSourceLayerId: 'Réteg',
        retrofitIds: 'Célgép',
        expiredDate: 'Lejárati dátum ',
        createDate: 'Lejárati adat',
        rectangle: 'Támogatott tartomány',
        lastUpdated: 'Utoljára frissítve',
      },
      status: {
        error: 'Hiba',
        converting: 'Átalakítás',
        sending: 'Áthelyezés',
        sent: 'Áthelyezve',
        failtosend: 'Áthelyezés sikertelen',
      },
    },
    compactionMaterials: {
      name: 'Anyagok',
      fields: {
        name: 'Név',
      },
    },
    weathers: {
      name: 'Időjárások',
      fields: {
        name: 'Név',
      },
    },
    operators: {
      name: 'Gépkezelők',
      fields: {
        name: 'Név',
      },
    },
    compactionWorkAreas: {
      name: 'Tömörítési munkaterületek',
      fields: {
        name: 'Munkaterületek nevei',
        blockSize: 'Blokkméret',
        description: 'Leírás',
      },
    },
    compactionLayers: {
      name: 'Tömörítési rétegek',
      fields: {
        name: 'Név',
        thickness: 'Vastagság',
        passCount: 'Menetek száma',
        compactionMaterialId: 'Anyag',
        inUse: 'Állapot',
        description: 'Leírás',
      },
      inUse: {
        true: 'Használatban',
        false: 'Nincs használatban',
      },
    },
    gnssSettings: {
      name: 'Ntrip-beállítás',
      fields: {
        registCorporationName: 'Regisztrálta',
        ownerCorporationId: 'Vállalati azonosító',
        ownerCorporationName: 'Cég neve',
        name: 'Név',
        url: 'Kiszolgáló URL',
        portnum: 'Port',
        userId: 'ID',
        password: 'Jelszó',
        retrofitId: 'Célgép',
        status: 'Állapot',
        updateDate: 'Utoljára frissítve',
      },
      status: {
        waitforsend: 'Átalakítás',
        sending: 'Áthelyezés',
        sent: 'Áthelyezve',
        failtosend: 'Áthelyezés sikertelen',
      },
    },
    supportMode: {
      name: 'Támogatási üzemmód',
      fields: {
        enabled: 'Támogatási üzemmód engedélyezése',
        corporationId: 'Vállalati azonosító',
        corporationName: 'Cég neve',
        siteId: 'Munkaterület-azonosító',
        siteName: 'Munkaterület',
        latitude: 'Szélességi fok',
        longitude: 'Hosszúsági fok',
        workPeriodStartDate: 'Tényleges építési időszak (kezdési dátum)',
        workPeriodEndDate: 'Tényleges építési időszak (befejezési dátum)',
        photoUrl: 'Helyszínkép URL',
        description: 'Leírás',
      },
    },
    userNotices: {
      name: 'Értesítés',
      noticeType: {
        emergencyimportant: 'Vészhelyzet/fontos',
        emergency: 'Vészhelyzet',
        important: 'Fontos',
      },
      fields: {
        noticeType: 'Fontosság',
        noticeTypes: 'Fontosság',
        noticeCategory: 'Értesítés típusa',
        title: 'Cím',
        content: 'Tartalomjegyzék',
        publicationStartDate: 'Kezdési dátum és idő (UTC)',
        publicationEndDate: 'Befejezési dátum és idő (UTC)',
        file: 'Szerelékek',
        language: 'Nyelv',
        emergency: 'Vészhelyzet',
        important: 'Fontos',
      },
    },
    firmwares: {
      name: 'Firmware',
      fields: {
        version: 'Version',
        compatibilityLevel: 'Kompatibilitási szint',
        firmwareFile: 'Firmware-fájl',
        firmwareFileCheckSum: 'Firmware ellenőrző összeg',
        firstInitializationFile: '1. inicializálási fájl',
        firstInitializationFileCheckSum:
          '1. inicializálási fájl ellenőrző összeg',
        secondInitializationFile: '2. inicializálási fájl',
        secondInitializationFileCheckSum:
          '2. inicializálási fájl ellenőrző összeg',
      },
    },
    bucketShares: {
      name: 'Kanál megosztása',
      fields: {
        fromBucketId: 'Forráskanál azonosítója',
        corporationId: 'Vállalati azonosító',
        name: 'Név',
      },
    },
    extensionarmShare: {
      name: 'Kanálszár toldat megosztása',
      fields: {
        name: 'Név',
      },
    },
    retrofitCorporationLicenses: {
      name: 'Licenc',
      fields: {
        licenseId: 'Licencazonosító',
        licenseType: 'Típus',
        issuedAt: 'Kiadási dátum',
        expiresAt: 'Lejárati dátum ',
        retrofitId: 'Gép',
        comment: 'Leírás',
        licenseStatus: 'Állapot',
        licensePermission: 'Licenc',
        productType: 'Termék neve',
      },
      licenseStatus: {
        unlicense: 'Nincs kiosztva',
        disable: 'Érvénytelen',
        enable: 'Érvényes',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: 'Cég neve',
        namePlates: 'Fotó',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: 'Hosszmértékegység',
      },
    },
    geofences: {
      name: 'Geokerítés',
      fields: {
        type: 'Típus',
        name: 'Név',
        dimension: 'Méret',
        trigger: 'Műveletindító',
        valid: 'Érvényes',
        alertType: 'Riasztás típusa',
        startDate: 'Kezdési dátum',
        endDate: 'Befejezési dátum',
        transparency: 'Átlátszó',
        elevation: 'Magasság',
        height: 'Magasság',
        radius: 'Sugár',
        lastUpdated: 'Utoljára frissítve',
        points: 'Támogatott tartomány',
        thickness: 'Vastagság',
      },
      type: {
        line: 'Vonal',
        polygon: 'Sokszög',
        circle: 'Kör',
        point: 'Pont',
        wall: 'Fal',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: 'Érvényes',
        false: 'Érvénytelen',
      },
      alertType: {
        attention: 'Figyelem',
        notice: 'Megjegyzés',
        warning: 'Figyelmeztetés',
        caution: 'Vigyázat',
        danger: 'Veszély',
      },
      transparency: {
        true: 'Átlátszó',
        false: 'Átlátszatlan',
      },
    },
    geofenceAlerts: {
      name: 'Riasztások',
      fields: {
        timeStamp: 'Észlelés dátuma és ideje',
        geofenceName: 'Geokerítés neve',
        alertType: 'Riasztás típusa',
        collisionPoint: 'Ütközési pont',
        retrofit: {
          machineInfoMachineName: 'Gép',
        },
      },
      alertType: {
        attention: 'Figyelem',
        notice: 'Megjegyzés',
        warning: 'Figyelmeztetés',
        caution: 'Vigyázat',
        danger: 'Veszély',
      },
    },
    ntrips: {
      name: 'Ntrip felügyelete',
      fields: {
        name: 'Felhasználóazonosító',
        ntripPass: 'Jelszó',
        serial: 'Sorozatszám',
        customerCorporationId: 'Vállalati azonosító',
        customerCorporationName: 'Cég neve',
        ntrip: 'Állapot',
        numberOfExchanges: 'Átviteli utasítások száma',
        id: 'ID',
        licenceId: 'Licencazonosító',
        dateOfRegistration: 'Regisztráció dátuma',
        billingToCustomers: 'Számlázás ügyfelek felé',
        paymentToKomatsu: 'Fizetés a Komatsu felé',
        applicantId: 'Jelentkezői azonosító',
        applicant: 'Jelentkező',
        requestCorporationId: 'Kérelmező vállalat azonosítója',
        requestCorporationName: 'Kérelmező vállalat neve',
        isUsed: 'Használatban',
      },
      ntrip: {
        publish: 'Kifizetve',
        stop: 'Leállás',
        delete: 'Törölve',
      },
    },
    retrofitAlternateRegists: {
      name: 'Vezérlő megbízotti regisztrációja',
      fields: {
        id: 'ID',
        retrofitId: 'retrofitId',
        fromCorporationId: 'fromCompanyId',
        fromCorporationName: 'Vezérlő megbízotti regisztrációja',
        toCorporationId: 'A felhasználó',
        toCorporationName: 'Cég neve',
        isApproved: 'Állapot',
        createDate: 'Jelentkezés dátuma',
        actualDate: 'Jóváhagyás dátuma',
        transferUsers: 'E-mail',
        email: 'E-mail',
        lastUpdated: 'Utoljára frissítve',
      },
      isApproved: {
        true: 'Jóváhagyva',
        false: 'Nincs jóváhagyva',
      },
    },
    pendingApprovals: {
      name: 'Jóváhagyásra vár',
      fields: {
        approvalType: 'Típus',
        requestedBy: 'Jelentkező vállalat',
        lastUpdated: 'Utoljára frissítve',
      },
      approvalType: {
        assignment: 'Hozzárendelés',
        transfer: 'Áthelyezés',
        agent: 'Vezérlő megbízotti regisztrációja',
      },
    },
    retrofitSendbacks: {
      name: 'Visszaküldés',
      fields: {
        fromRetrofitId: 'Összeillesztésből',
        toRetrofitId: 'Alternatív vezérlő',
        notificationEmailAddress: 'E-mail',
        siteId: 'Munkaterület',
        corporationId: 'Vállalati azonosító',
        corporationName: 'Használt vállalat',
        ntripId: 'Ntrip információ',
        isComplete: 'Visszaküldés kész',
      },
    },
    siteBuriedStructures: {
      fields: {
        epsgCode: 'Epsg',
      },
    },
  },
};

export default message;
